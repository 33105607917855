.MuiListItem-root.MuiListItem-gutters {
    padding: 13px 7px 28px 1px;
}
.MuiMenuItem-root {
    height: auto;
}
.MuiCollapse-wrapperInner {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.custommenuitem {
    background-color: #b9b6b6;
    padding-top: 2px;
    padding-bottom: 8px !important;
    border-bottom: 1px solid #e5e4e4;
    width: 115%;
    margin-left: -7.5%;
    bottom: -5px;
}
.cuslist {
    background-color: #e5e4e4;
}
