/* Stolzl_Bold.ttf */
@font-face {
    font-family: 'Stolzl';
    src: url('fonts/stolzl/Stolzl_Bold.ttf') format('truetype');
    font-weight: bold; /* or 700 */
    font-style: normal;
}

/* Stolzl_Book.ttf */
@font-face {
    font-family: 'Stolzl';
    src: url('./fonts/stolzl/Stolzl_Book.ttf') format('truetype');
    font-weight: normal; /* or 400 */
    font-style: normal;
}

/* Stolzl_Light.ttf */
@font-face {
    font-family: 'Stolzl';
    src: url('./fonts/stolzl/Stolzl_Light.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
}

/* Stolzl_Medium.ttf */
@font-face {
    font-family: 'Stolzl';
    src: url('./fonts/stolzl/Stolzl_Medium.ttf') format('truetype');
    font-weight: 500; /* Medium */
    font-style: normal;
}

/* Stolzl_Regular.ttf */
@font-face {
    font-family: 'Stolzl';
    src: url('./fonts/stolzl/Stolzl_Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}

/* Stolzl_Thin.ttf */
@font-face {
    font-family: 'Stolzl';
    src: url('./fonts/stolzl/Stolzl_Thin.ttf') format('truetype');
    font-weight: 100; /* Thin */
    font-style: normal;
}

/* PPObjectSans_Bold.ttf */
@font-face {
    font-family: 'SansBold';
    src: url('./fonts/sans/PPObjectSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* PPObjectSans_Medium.ttf */
@font-face {
    font-family: 'SansMedium';
    src: url('./fonts/sans/PPObjectSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* PPObjectSans_Regular.ttf */
@font-face {
    font-family: 'SansRegular';
    src: url('./fonts/sans/PPObjectSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

:root {
    --dark-blue: #27344b;
    --orange-color: #f99c24;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: #1d2434;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.font-sans {
    font-family: 'Open Sans', sans-serif !important;
}
.font-roboto * {
    font-family: 'Roboto', sans-serif !important;
}

.font-stolzl {
    font-family: 'stolzl', sans-serif !important;
}
.font-poppins {
    font-family: 'Poppins', sans-serif !important;
}

.AppLayoutScrollbar::-webkit-scrollbar {
    width: 18px !important;
    /* height: 8px; */
    background-color: transparent;
}

.AppLayoutScrollbar::-webkit-scrollbar-thumb {
    background: #c8c8c8;
}
.approval-animate-opacity:hover::-webkit-scrollbar-thumb {
    background: #c8c8c8;
}
.order-animate-opacity:hover::-webkit-scrollbar-thumb {
    background: transparent;
}
.animate-opacity:hover::-webkit-scrollbar-thumb {
    background: #c8c8c8;
}

.customized-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}
.customized-scrollbar::-webkit-scrollbar-thumb {
    background: #c8c8c8;
}
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-corner {
    background: transparent;
}

:hover::-webkit-scrollbar-thumb {
    background: #c8c8c8;
}
.customized-scrollbar-error::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}
.customized-scrollbar-error:hover::-webkit-scrollbar-thumb {
    background: #f1a5ae;
}
.animate-opacity {
    animation: opac 0.4s;
}
.approval-animate-opacity {
    animation: opac 0.4s;
}
.order-animate-opacity {
    animation: opac 0.4s;
}
@keyframes opac {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.m0 {
    margin: 0;
}

.key:nth-of-type(even) {
    background: #f5f5f5;
}
.key2:nth-child(4n + 1),
.key2:nth-child(4n + 2) {
    background: #f5f5f5;
}
.btn-disabled.Mui-disabled {
    background: #f8f8f8;
    color: #a5a5a5 !important;
    /*border: 1px solid #616c87 !important;*/
    border-radius: 5px;
}
.btn-disabled-submit.Mui-disabled {
    background: #626262 !important;
    color: #a5a5a5 !important;
    border: 1px solid #616c87 !important;
}
.MuiStepLabel-label {
    font-size: 14px !important;
}

.MuiTablePagination-menuItem {
    color: #000000 !important;
}

.MuiTablePagination-root {
    border-bottom: none !important;
}

.validate fieldset {
    border: 1px solid #ff0000 !important;
}
.validate label {
    color: #ff0000 !important;
}
.validate1 label {
    color: rgb(255, 115, 0) !important;
}
.validate-textarea {
    border: 1px solid #ff0000 !important;
}
.success fieldset {
    border: 1px solid #228b22 !important;
}

.tooltip-info-icon {
    margin-top: 3px;
    height: 27px;
}

.customTab .MuiTabs-indicator {
    height: 0px !important;
}

.tags label {
    color: #0047ba;
    font-weight: 500;
    padding: 0 2px;
    font-size: 14px;
}
.fieldSetStyle {
    border-width: 1px;
    border-style: solid;
    border-color: #e9e9e9;
    border-radius: 3px;
}
.option-wrapper {
    margin: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    border-radius: 0px;
}
.inline-wrapper {
    border-width: 0.5px;
    border-style: solid;
    border-color: #cccccc;
    border-radius: 2px;
    margin-right: 6px;
}
.rdw-option-disabled {
    opacity: 0.5 !important;
}
.single-wrapper {
    border-width: 1.5px;
    border-style: solid;
    border-color: #cccccc;
    border-radius: 2px;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0.75, 0.75);
    }
    30% {
        transform: scale(1.2, 1.2);
    }
    70% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.2, 1.2);
    }
}
.setoverFlow div div {
    overflow-y: unset !important;
}
.text-ellipsis--2 {
    text-overflow: ellipsis;
    overflow: hidden;
    /* // Addition lines for 2 line or multiline ellipsis */
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.boxShadowclass:hover {
    transform: scale(1.1);
    /* box-shadow: 0 0 8px white;  */
}
.fullwidth {
    width: '100%';
}
/* .blackText div .MuiBox-root div.MuiTypography-root{
color:'black'
} */
/*.env-details .lable {*/
/*    font: normal normal bold 16px Roboto !important;*/
/*    color: #000000 !important;*/
/*}*/
.connection .key {
    color: #000000;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    background-color: #ffffff !important;
}

.env-details .label {
    color: #000000;
    font-size: 14.5px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.env-details .value {
    color: #000000;
    font-size: 14.5px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
.preview-details .label {
    color: #000000;
    font-size: 14.5px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.preview-details .value {
    color: #000000;
    font-size: 14.5px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
.provision-heading {
    color: #00a9e7;
}
.app-details .label {
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: bold;
}
.app-details .value {
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: normal;
}
.stackDetails {
    padding-left: 32px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.order-details .label {
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    white-space: break-spaces !important;
}
.order-details .value {
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
.cart-details .label {
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    width: auto;
    padding: '2px';
    overflow: visible !important;
}
.cart-details .value {
    color: #000000;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    padding: '2px';
}
.cart-details div div {
    padding: 2px !important;
}
.MAINCLASS div div fieldset {
    border: 1px solid #228b22;
}

.activeArrow.Mui-active:first-child svg {
    opacity: 0.5;
    color: #7fa3dc !important;
}
.tablecheckbox.Mui-checked svg {
    color: #0047ba;
}
.borderclass::before {
    background-color: #ffffff;
    content: none;
}
.custom-btn.MuiButton-containedPrimary:hover {
    background: #1976d2 !important;
}
.custom-btn.MuiButton-containedSuccess:hover {
    background: #2e7d32 !important;
}
.custom-btn.MuiButton-outlinedPrimary:hover {
    background-color: transparent !important;
    border: 1px solid rgba(25, 118, 210, 0.5) !important;
}
.custom-btn.MuiButton-containedError:hover {
    background-color: #d32f2f !important;
    border: 0 !important;
}
.cartitemhead div div div div div {
    color: 'red';
}
.permonth {
    top: -5% !important;
}
.slick-slider {
    margin: 0px 45px 10px 45px;
}
.slick-dots {
    left: -24px !important;
}
/* .slick-list {
    margin: 0px 65px 0px 39px !important;
} */
/* .slick-next::before {
    color: rgb(46, 46, 46) !important;
}
.slick-prev::before {
    color: rgb(46, 46, 46) !important;
} */
.viewMoreLink {
    color: #000000;
}
.slick-prev {
    height: 150px !important;
    width: 25px !important;
}
.slick-next {
    height: 150px !important;
    width: 25px !important;
}
.slick-slide {
    outline: none;
    width: 284px !important;
    margin-right: 11px;
}
.slick-track {
    display: flex !important;
}
@media (min-width: 815px) and (max-width: 825px) {
    .slick-next {
        right: 15px !important;
    }
}
.slick-disabled {
    display: none !important;
}

.control-dots {
    display: none;
}
@media only screen and (min-width: 1024px) {
    .monthlycost {
        margin-left: 40px !important;
    }
    .oneTimecost {
        margin-left: 31px !important;
    }
    .totreccost {
        padding-left: 5px !important;
    }
    .totonecost {
        padding-left: 5px !important;
    }
    .permonth {
        position: relative;
        top: -15% !important;
    }
    /* .permonth{
       padding-left: 32% !important;
       
    } */
    /* width:{md:"60%",lg:"50%",xl:"40%"} */
}

@media (min-width: 1680px) and (max-width: 1920px) {
    .permonth {
        padding-left: 18% !important;
    }
}

@media (min-width: 1440px) and (max-width: 1680px) {
    .permonth {
        padding-left: 20% !important;
    }
}
@media (min-width: 1366px) and (max-width: 1440px) {
    .permonth {
        padding-left: 21% !important;
    }
}
@media (min-width: 1280px) and (max-width: 1366px) {
    .permonth {
        padding-left: 22% !important;
    }
}
@media (min-width: 1024px) and (max-width: 1280px) {
    .permonth {
        padding-left: 23% !important;
    }
}
@media only screen and (max-width: 1024px) {
    .permonth {
        padding-left: 25% !important;
    }
}
.paginationBtn button {
    margin-left: 15px;
    margin-right: 15px;
}
.paginationBtn .MuiButtonBase-root svg {
    color: #0047ba;
}
.paginationBtn .MuiButtonBase-root.Mui-disabled svg {
    color: #a5a5a5;
}
label.Mui-disabled {
    color: #a5a5a580 !important;
}
label.Mui-disabled + div fieldset {
    border: 1px solid #a5a5a580 !important;
    color: #a5a5a580 !important;
}

.preconfig-details div {
    background-color: #ffffff !important;
}

.preconfig-details div div.label {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}
.preconfig-details div div.value {
    font-family: 'Roboto';
    font-size: 14px;

    color: #000000;
}
.landFilter {
    inset: 0px auto auto -39px !important;
}
.MuiButton-root {
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    text-transform: none !important;
}
.lz-summary:after {
    background: linear-gradient(to right, #0047ba 25%, #00a0dd 25%, #00a0dd 50%, #f99c24 50%, #f99c24 75%, #f9e61e 75%);
    position: absolute;
    content: '';
    height: 10px;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
}
.type-to-search .clear-indicator {
    visibility: hidden;
}
.type-to-search:hover .clear-indicator {
    visibility: visible;
}

.ripreview-details div {
    background: #ffffff !important;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
}
.ripreview-details .label {
    color: #000000;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.label-space {
    white-space: unset !important;
    letter-spacing: 0.3px;
}

.value-space {
    white-space: unset !important;
    letter-spacing: 0.3px;
}
.ripreview-details .value {
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.blink-bg {
    color: #fff;
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    animation: blinkingBackground 4s infinite;
}
.ri-layout .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-right: 10px !important;
}
.ritmchipcontainer::before {
    border-bottom: none !important;
}
.ritmvalidate {
    border-color: #ff0000 !important;
}
.ritmCheckbox {
    color: #ff0000 !important;
}

@keyframes blinkingBackground {
    0% {
        background-color: #04a1d5;
    }
    25% {
        background-color: #ffffff;
    }
    50% {
        background-color: #04a1d5;
    }
    75% {
        background-color: #ffffff;
    }
    100% {
        background-color: #04a1d5;
    }
}
.customdialogpaper {
    max-width: 1000px !important;
}
.dlops_margin_left {
    margin-left: 20px !important;
}
.dlops-custom-bar-chart .recharts-legend-item-text {
    color: #000000 !important;
    font-size: 13px !important;
}
/* Style for the scrollbar */
.scrollable::-webkit-scrollbar {
    width: 0px; /* Adjust width as needed */
}
.scrollable:active::-webkit-scrollbar {
    width: 10px; /* Adjust width as needed */
}
.scrollable:focus::-webkit-scrollbar {
    width: 10px; /* Adjust width as needed */
}
.scrollable.scrolling::-webkit-scrollbar {
    width: 10px; /* Adjust width as needed */
}

/* Style for the scrollbar track */
.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

/* Style for the scrollbar thumb */
.scrollable::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
}

/* Hover effect for the scrollbar thumb */
.scrollable:hover::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb on hover */
}
.charScrollbar::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}
.scrollable .content div .dashboardWrapper {
    /* background-color: #228b22; */
}
.scrollable .content div .dashboardWrapper::-webkit-scrollbar {
    width: 10px; /* Adjust width as needed */
}
.scrollable .content div .dashboardWrapper::-webkit-scrollbar:hover::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb on hover */
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .customResourceCardContainer {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }
}
.rwc-hide {
    display: none;
}
